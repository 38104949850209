.weddingPage {

    text-align:center;
    margin-left:auto ;
    margin-right:auto
}

.weddingImages {
    max-width: 100%;
    max-height: 100%;
}

.imageOne {
    padding: 0px 0px 40px 0px;
}

.imageThree {
    max-width: 1000px;
}

