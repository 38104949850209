.column {
    float: left;
    width: 29%;
    padding: 2%;
    
  }

  .columnHome{
    float: left;
    width: 33%;
    padding: 2%;
  }


  .columnContainer {
    border-radius: 0px;
    border: 1px solid rgb(216, 216, 216);
  }
  
  .row {
    padding-left: 3% !important;
    padding-right: 1% !important;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  @media screen and (max-width:600px) {
    .column {
      width: 100%;
    }
  }

  .horseNews {
    color: rgb(0, 0, 0);
    font-family: Playfair-Bold;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    padding-left: 3% !important;
    padding-top: 3% !important;
    padding-bottom: 5%;
    min-height: 75px !important;
  }

  .horseNewsDate {
    padding-left: 3% !important;
    padding-top: 3% !important;
    padding-bottom: 3% !important;
    color: rgb(120, 118, 121);
    font-family: Open-Sans;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.71px;
    text-transform: uppercase;
  }

  .mainTextBodyHome{
    background: rgb(243, 246, 245);
    border-radius: 0px;
    padding-left: 5% !important;

  }

  .mainTextBodyHeader{
    padding-top: 3%;
    padding-bottom: 2%;
    color: rgb(74, 74, 74);
    font-family: Open-Sans;
    font-size: 14px;
    font-weight: 400;
    height: 19px;
    letter-spacing: 2px;
  }

  .newsLink {
    text-decoration: none;
  }

  .subSectionText{
    color: rgb(0, 0, 0);
    font-family: Georgia;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 28.5px;
    padding-bottom: 2%;
    padding-right: 4%;
  }

  .newsHeader{
    padding-left: 5% !important;
    padding-top: 2%;
    color: rgb(0, 0, 0);
    font-family: Playfair-Bold;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .newsSubHeader{
    color: rgb(0, 0, 0);
    font-family: Playfair-Bold;
    font-size: 16px;
    font-weight: bold;
    height: 32px;
    letter-spacing: 0px;
    text-align: right;
    padding-right: 6%;
    text-decoration: none;
    padding-bottom: 5%;
  }


  .heroImage{
    width: 100%;
    height: auto;
  }

  .helpmathew {
    width: 500px;
    height: 500px;
  }

  .glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

