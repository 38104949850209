body { 
  margin: 0;
}

.header {
  overflow: hidden;
  background-color: #FFFFFF;
  padding: 20px 10px;
  border-bottom: 3px solid #F7A800;
  height: 100px;
}

.headerWedding {
  overflow: hidden;
  background-color: #FFFFFF;
  padding: 20px 10px;
  height: 100px;
}

.mainLogo{
  padding-top: 10px !important;
}

.header a {
  float: left;
  color: #333333;
  text-align: center;
  text-decoration: none;
  font-size: 14px; 
  line-height: 25px;
  border-radius: 4px;
}

.weddinLink  {
  float: left;
  color: #333333;
  text-align: center;
  text-decoration: none;
  font-size: 14px; 
  line-height: 25px;
  border-radius: 4px;
}

.linkHeader{
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 19px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.headerWedding a.logo {
  font-size: 25px;
  font-weight: bold;
}


.header-right a{
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 19px;
}

.header a.active {
  color: #004336;
  font-family: Open-Sans-Bold !important;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 19px;
  font-weight: 600;
}

.header a:hover {
  color: #004336;
  font-family: Open-Sans-Bold !important;
  padding-bottom: 12px;
  font-weight: 600;
}

.weddingOverwrite {
  border-bottom: 0px solid #F7A800 !important;
}

.header-right {
  float: right;
  color: rgb(78, 78, 78);
  font-size: 14px;
  font-family: Open-Sans;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.wedding-header-right {
  float: right;
  color: rgb(78, 78, 78);
  font-size: 14px;
  font-family: Open-Sans;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.header-right a:hover {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 19px;
  font-weight: 600;
}

/* Wedding */

.weddingLogo {
  margin-top: -12px !important;
}



.buttonWedding {
  background-color: #3B4B2A;
  border: none;
  color: white !important;
  text-align: center;
  text-decoration: none;
  border-radius: 30px !important;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 36px !important;
  margin-top: 8px !important;
  margin-left: 20px !important;
}

@media screen and (max-width: 822px) {
  .header a {
    float: none;
    display: block;
    text-align: center;
  }
  
  
  .header-right {
    float: none;
  }
  .header {
    height: 100%;
  }


}

@media screen and (max-width: 822px) {
  .wedding-header {
    float: none;
    display: block;
    text-align: center;
  }
  
  
  .wedding-header-right {
    float: none;
  }
  .wedding-header {
    height: 100%;
  }


}

@media screen and (max-width: 769px) {
  .mainTextBodyHeader{
    padding-bottom: 6%;
  }

  .mainTextBodyHeader{
    padding-top: 3%;
  }

  .mainSocialFooter{
    padding: 1% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .mainLogoFooter{
    padding-top: 3% !important;
    padding-bottom: 1% !important;
  }
}

