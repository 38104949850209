.heroImageServices {
    width: 100%;
    height: auto;
}

.column {
    float: left;
    width: 49%;
    padding: 2%;
    
  }
  
  .row {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  @media screen and (max-width:600px) {
    .column {
      width: 100%;
    }
  }

  .mainTextBodyHeaderServices{
    color: rgb(0, 0, 0);
    font-family: PlayfairDisplay-Bold;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    padding-left: 5% !important;
    padding-top: 2%;
  }

  .horseInfoTitle{
    color: rgb(0, 72, 58);
    font-size: 14px;
    font-family: Open-Sans;
    font-weight: 400;
    letter-spacing: 2px;
    padding-top: 2%;
  }

  .horseInfoTitleGraduates {
      color: rgb(0, 72, 58);
      font-size: 17px;
      font-family: Georgia;
      font-weight: 400;
      padding-top: 1.4%;
  }

  .horseInfoText{
    color: rgb(0, 0, 0);
    font-size: 17px;
    font-family: Georgia;
    letter-spacing: 0px;
    line-height: 28.5px;
    padding-top: 1%;
    padding-bottom: 4%;
  }
