.graduatesText {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: Georgia;
    letter-spacing: 0px;
    line-height: 28.5px;
    padding-top: 1%;
    padding-bottom: 4%;
}
#container {width:800px;margin:0 auto; padding:20px;}
.benefits-block {width:100%; margin-bottom:20px;}
.benefits-left {width:350px; float:left;}
.benefits-right {width:350px; float:right;}
.benefits-block img {width:350px; height:350px; border:1px solid blue;}
.group:after {content:""; display:table; clear:both;}

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  #container {width:90%; text-align:center;}   
  .benefit-block {width:100%; text-align:center;}
  .benefits-left {width:100%; float:none;}
  .benefits-right {width:100%; float:none;}
}

.boxes {
  padding-left: 5% !important;
}

.graduatesNames {
    color: #000000;
    font-size: 24px;
    font-family: Playfair-Bold;
    font-weight: 400;
    padding-top: 2%;
  }

  .mainTextBodyHeaderSales {
    color: rgb(0, 0, 0);
    font-family: Playfair-Bold;
    font-size: 24px;
    letter-spacing: 0px;
    padding-left: 5% !important;
    padding-top: 2%;
  }

  .mainTextBodyHeaderServicesMain {
    color: rgb(0, 0, 0);
    font-family: Playfair-Bold;
    font-size: 24px;
    letter-spacing: 0px;
    padding-left: 5% !important;
    padding-top: 2%;
  }

  .graduatesDivider {
    padding-top: 1.5%;
    border-bottom: 1px solid #dedede;
    margin: 0% 7% 0% 5%;
  }

  li {
    list-style-type: none;
    border-left: 5px solid #ccd9d7;
    padding-left: 2%;
  }

  ul {
    padding-inline-start: 0px;
  }