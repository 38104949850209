.footer {
    background: rgb(0, 38, 31);
    border-radius: 0px;
    border-top: 3px solid #F7A800;
    clear: both;
}

.footerContainer{
    padding: 1%;
    text-align: center;
}

.copyText{
    color: rgb(255, 255, 255);
    font-size: 10px;
    font-family: Open-Sans;
    text-align: center;
    letter-spacing: 0px; 
}

.mainLogoFooter {
    padding: 0.5%;
}

.mainSocialFooter {
    padding: 0.5%;
}