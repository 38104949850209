@font-face {
	font-display: swap;
	  font-family: "Open-Sans";
	  font-style: normal;
	  font-weight: 400;
      src: url(../fonts/OpenSans-Regular.ttf) format("truetype");
  }

  @font-face {
	font-display: swap;
	  font-family: "Open-Sans-Bold";
	  font-style: normal;
	  font-weight: 700;
      src: url(../fonts/OpenSans-Bold.ttf) format("truetype");
  }
  

  @font-face {
	font-display: swap;
	  font-family: "Playfair-Bold";
	  font-style: bold;
	  font-weight: 600;
      src: url(../fonts/PlayfairDisplay-Bold.ttf) format("truetype");  }



  /* body {
	font-family: Open-Sans !important;
} */

/* .intelo-bold {
	font-family: $intelBold !important;
}

.intelo {
	font-family: $intelo !important;
} */