.mainTextBodyHeaderGallery{
    color: rgb(0, 0, 0);
    font-size: 17px;
    font-family: Georgia;
    letter-spacing: 0px;
    line-height: 28.5px;
    padding-left: 5%;
    padding-top: 0.8%;
    padding-right: 5%;
}

.gallerySubText {
    padding-bottom: 2%;
}

.developerFun{
    padding-bottom: 1%;
    color: white;
}
  
  div.gallery img {
    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 20%;
  }
  
  @media only screen and (max-width: 768px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 3%;
  }